$(function () {

    void 0;

    var movimentoParaCima = false;
    var ultimaPosicaoScroll = 0;
    var toleranciaDesceMenu = 20;


    var sizeDesktop = 1170;
    var interval = null;

    var quantity = $('#operation-carrousel').find('article');
    var tab_operation = 1;


    /**
     *   Carregando Utilitários
     * */
    var $utility = new cemf.js.Utility();
    $utility.startWeb();


    function loadOpInterval() {
        if (interval == null)
            interval = setInterval(function () {
                nextOperation();
            }, 3000);
    }

    function clearOpInterval() {
        clearInterval(interval);
        interval = null;
    }

    function nextOperation() {
        // Alterando compartamento pelo tamanho da tela

        if ($utility.screenSize() < sizeDesktop) {
            // Table
            $('#operation-carrousel').find('article').css({'display': 'none'});
            $('.operation_carrousel_action [data-action]').css('opacity', '0.3');
        } else {
            // Desktop
            $('#operation-carrousel').find('article').css({'display': 'flex'});
            $('#operation-carrousel').find('article header').css({'background': '#000000'});
        }

        $("[data-tab= op-" + tab_operation + "]").css({'display': 'flex'});
        $("[data-tab= op-" + tab_operation + "] header").css({'background': '#fec204'});
        $(".operation_carrousel_action [data-action=op-" + tab_operation + "]").css('opacity', '1');
        tab_operation++;
        if (tab_operation > quantity.length) {
            tab_operation = 1;
        }
    }


    // Interface Dinamica pelo mvimento da tela
    $(document).on('scroll', function () {
        scrollEvent();
    });


    /*
       MENU EVENT SCROLL
    * */
    function scrollEvent() {
        var posicaoScroll = $(document).scrollTop();
        if (posicaoScroll >= (ultimaPosicaoScroll + toleranciaDesceMenu)) {
            movimentoParaCima = false;
            ultimaPosicaoScroll = posicaoScroll;

        } else if (posicaoScroll <= ultimaPosicaoScroll) {
            movimentoParaCima = true;
            ultimaPosicaoScroll = posicaoScroll;
        }

      //  console.log(posicaoScroll);
      //  console.log((movimentoParaCima) ? "CIMA" : "BAIXO");

        if (posicaoScroll < 37) {  // Laranja
            $(".main_header")
                .css({
                    position: 'fixed',
                    background: '#000000'
                })
                .removeClass('shadow-top-z-3');

            //ffbe00

        } else if (posicaoScroll > 67) { // ROXO
         //   console.log("ALTERANDO COR 2");

            var tamanho_layout = $('.boxed-layout').width();
            $(".main_header")
                .css({
                    position: 'fixed',

                })
                .addClass("shadow-top-z-3");
        }
    }


    /**
     *    OPERATION
     *    INTERVAL PÁRA MUDANÇA DE CARDS
     *    NEXT CARD PELO ACTION BUTTOM
     */

    nextOperation();
    loadOpInterval();

    $('[data-tab]').hover(function () {
        if ($utility.screenSize() < sizeDesktop) {
            void 0;
            clearOpInterval();
        }
    }, function () {
        if ($utility.screenSize() < sizeDesktop) {
            void 0;
            loadOpInterval();
        }
    });

    $('.operation_carrousel_action').hover(function () {
        void 0;
        clearOpInterval();
    }, function () {
        void 0;
        loadOpInterval();
    });

    $("[data-action]").on('click', function () {
        clearOpInterval();
        tab_operation = $(this).data('action');
        tab_operation = tab_operation.substr(3, 3);
        nextOperation();
    })

    /*  MAPA CLIQUE NA REGIAO */
    $(".cities_map a").on("click", function () {
        void 0;
        var city = $(this).attr("xlink:href");
        $("[data-state]").addClass('none');
        $(".cities_content").removeClass('none');

        if($utility.exists($("[data-state='"+city+"']"))) {
            $("[data-state='" + city + "']").removeClass('none');
        }else{
            $("[data-state='wait']").removeClass('none');
        }

        $utility.scrollPage($(".start_cities"));
    });

});